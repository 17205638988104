<template>
  <div class="login-container">
    <div class="card">
       <div class="logo">
          <img src="../assets/logo.png" alt="">
      </div>
      <input v-model="form.login" placeholder="Login" />
      <input type="password" v-model="form.password" placeholder="Password" />
      <div class="btn" @click="submit()">Se Connecter</div>
    </div>
  </div>
</template>

<script>
import bcrypt from "bcryptjs";
export default {
  name: "Login",
  data() {
    return { form: { login: "", password: "" } };
  },
  methods: {
    submit: function () {
      if (
        this.checkHash(
          this.form.login,
          "$2a$10$zuBG/0oXsHhrZ/8UsDTa.OxE3e3/94CDy0lyqv.uYBi8FBxEl1L4a"
        ) &&
        this.checkHash(
          this.form.password,
          "$2a$10$MlAmhyjzBxRn/wEl1ZJtyun3m/f8dcoRSlnRTH74K9gm3oYnPcMMa"
        )
      ) {
    localStorage.isLogged="$2a$10$zuBG/0oXsHhrZ/8UsDTa.OxE3e3/94CDy0lyqv.uYBi8FBxEl1L4a";
    // this.$router.push(this.$route.query.redirect || '/')
    this.$router.push('/')
      }
    },
    checkHash: function (value, crypted) {
      return bcrypt.compareSync(value, crypted);
    },
  },
};
</script>
<style scoped>
.login-container {
  height: 100%;
  background:#4a69bd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 50%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
input,
.btn {
  padding: 1%;
  width: 50%;
  margin: 1%;
}
.btn {
  border: solid 1px #e55039;
  text-align: center;
  font-weight: bolder;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background: #e55039;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
  .logo {
    width: 50%;
  }
  img {
      width: 100%;
      height: auto;
    }
</style>