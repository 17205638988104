<template>
  <div class="detail-container">
    <div class="header">
      Détail Commande
      <i class="fa fa-sign-out" @click="logout()" aria-hidden="true"></i>
    </div>
    <div class="body">
      <div class="grid">
        <div class="customer-info">
          <div class="title">Client</div>
          <p class="client-info">
            Nom : <span>{{ order.client.first_name }}</span>
          </p>
          <p class="client-info">
            Prénom : <span>{{ order.client.last_name }}</span>
          </p>
          <p class="client-info">
            Téléphone : <span>{{ order.client.phone }}</span>
          </p>
          <p class="client-info">
            Ville: <span>{{ order.client.address }}</span>
          </p>
          <p class="client-info">
            Adresse: <span>{{ order.client.city }}</span>
          </p>
        </div>
        <div class="orders-info">
          <div class="title">Commande</div>
          <p class="order-info">
            Passée le : <span>{{ getDateFormat(order.created) }}</span>
          </p>
          <p class="order-info">
            Prévue pour :
            <span>{{ getDateFormat(order.order_time.dateTime) }}</span>
          </p>
          <p class="order-info">
            Type : <span>{{ order.type }}</span>
          </p>
          <p class="order-info">
            Méthode de paiement : <span>{{ order.payment_type }}</span>
          </p>
          <p class="order-info">
            Status : <span>{{ order.statut }}</span>
          </p>
          <p class="order-info">
            Total : <span class="total">{{ order.total.toFixed(2) }} €</span>
          </p>
        </div>
        <div class="order-detail">
          <div class="title detail">Détail</div>
          <ul class="list-parent">
            <li v-for="item in order.items" v-bind:key="item">
              <p>
                ({{ item.quantity }}X) {{ item.product }}
                {{ item.size ? "(" + item.size + ")" : "" }}
                <span>{{ item.priceTotal.toFixed(2) }} € </span>
              </p>
              <ul>
                <li
                  v-for="supplement in item.supplements"
                  v-bind:key="supplement"
                >
                  {{ supplement.name }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Detail",
  data() {
    return { order: {} };
  },
  created() {
    this.order = JSON.parse(localStorage.current_order);
  },
  methods: {
    logout: function () {
      localStorage.removeItem("isLogged");
      this.$router.go("/");
    },
    getDateFormat: function (value) {
      return moment(value).format("DD/MM/yyyy HH:mm");
    },
  },
};
</script>
<style scoped>
.detail-container {
  height: 100%;
}
.header {
  text-align: center;
  font-size: large;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1%;
    background: #4a69bd;
  color: white;
}
.body {
  padding: 2% 3%;
  height: 80%;
}
i {
  float: right;
  cursor: pointer;
}
.grid {
  display: grid;
}
.customer-info {
  padding: 0 0.5%;
}
.orders-info {
  padding: 0 0.5%;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: large;
  padding: 1%;
}
p {
  display: flex;
  justify-content: space-between;
}
ul {
  list-style: none;
  padding: 0 3%;
}
.list-parent {
  padding: 0 2%;
}
@media screen and (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (min-width: 901px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  height: 100%;
  }
  .order-detail {
  grid-column: 2;
  grid-row: 1/3;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px gray;
}
.customer-info {
  border-radius: 5px;
  padding: 0 10%;
  box-shadow: 0px 0px 10px gray;
}
.orders-info {
  border-radius: 5px;
  padding: 0 5.5%;
  box-shadow: 0px 0px 10px gray;
}
.list-parent {
  padding: 0 20%;
}
.detail{
  position: sticky;
  top: 0;
  background: white;
}
}
</style>
