<template>
  <div class="app-container">
    <div class="header">
      tout le monde
      <i class="fa fa-sign-out" @click="logout()" aria-hidden="true"></i>
    </div>
    <div class="body">
      <PosTable />
    </div>
  </div>
</template>

<script>
import PosTable from "../components/table";
export default {
  name: "Stats",
  components: {
    PosTable,
  },
  methods: {
    logout: function () {
      localStorage.removeItem("isLogged")
      this.$router.go('/')
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-container {
  height: 100%;
}
.header {
  text-align: center;
  font-size: large;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1%;
  background: #4a69bd;
  color: white;

}
.body {
  padding: 1%;
  
}
i {
  float: right;
  cursor: pointer;
}
</style>
