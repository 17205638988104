<template>
 <router-view/>
</template>
<script>
export default {
  name: "OrdersRouter",
}
</script>
<style scoped>
</style>
