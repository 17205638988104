<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th v-for="h in data.header" v-bind:key="h" @click="sort(h.key)">
            {{ h.title }}
          </th>
          <th>Détail</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="r in data.content" v-bind:key="r">
          <td>{{ r.name }}</td>
          <td>{{ r.licence_id }}</td>
          <td>{{ r.nb_orders }}</td>
          <td>{{ getDateFormat(r.last_order) }}</td>
          <td>{{ r.turnover.toFixed(2) }} €</td>
          <td @click="navigate(r)"><i class="fa fa-sitemap" aria-hidden="true"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "PosTable",
  data() {
    return {
      companies: [],
      data: {
        header: [
          { key: "name", title: "Restaurant" },
          { key: "licence_id", title: "Licence" },
          { key: "nb_orders", title: "Nombre Commande" },
          { key: "last_order", title: "Derniere Commande" },
          { key: "turnover", title: "CA" },
        ],
        content: [],
        currentSort: "nb_orders",
        currentSortDir: "desc",
      },
    };
  },
  methods: {
    sort: function (s) {      
      if (s === this.data.currentSort) {
        this.data.currentSortDir =
          this.data.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.data.currentSort = s;
      this.data.content.sort((a, b) => {
        if (s === "last_order") {
          if (this.data.currentSortDir === "asc") {
            return new Date(a[s]) - new Date(b[s]);
          } else return new Date(b[s]) - new Date(a[s]);
        } 
        else if(s=="name"){      
           if (this.data.currentSortDir === "asc") {
             return a[s][0] > b[s][0] ? -1 : 1
          } else return  a[s][0] > b[s][0] ? 1 : -1
        }
        else {
          if (this.data.currentSortDir === "asc") {
            return a[s] - b[s];
          } else return b[s] - a[s];
        }
      });
    },
    getDateFormat: function (value) {
      return moment(value).format("DD/MM/yyyy HH:mm");
    },
    navigate: function (r) {
      localStorage.setItem("orders", JSON.stringify(r.orders));
      localStorage.setItem("restaurant", JSON.stringify(r.name));
      this.$router.push("/orders");
    },
    getInfo:function(url,url1) {
       this.axios.get(url).then((response) => {
        this.axios.get(url1).then((response1) => {
        this.data.content = filterData(response1.data,response.data);
      });
      });
    }
  },
  created: async function () {
    let url0="https://posadmin.biborne.com/users/companies";
    let url1="https://api1.biborne.com/orders";
    this.companies=this.getInfo(url0,url1)
  },
};
/*
http://localhost:3006/api/users/companies
https://api1.biborne.com/orders
http://localhost:3005/api/orders*/
var filterData = (data,companies) => {
  data = data.filter(
    (d) => d.status === "pending" || d.status === "paid" || d.status === "ready"
  );
  let filtered_list = [];
  for (let i = 0; i < data.length; i++) {
    let itemFound = false;
    for (let k = 0; k < filtered_list.length; k++) {
      if (data[i].procaisse_licence_id === filtered_list[k].licence_id) {
        itemFound = true;
        filtered_list[k].nb_orders++,
          filtered_list[k].orders.push(data[i]),
          (filtered_list[k].last_order = moment(data[i].created).isAfter(
            filtered_list[k].last_order
          )
            ? data[i].created
            : filtered_list[k].last_order),
          (filtered_list[k].turnover += data[i].total);
        break;
      }
    }
    if (!itemFound) {
      filtered_list.push({
        name:companies[data[i].procaisse_licence_id]!==undefined?
        companies[data[i].procaisse_licence_id]:"",
        licence_id: data[i].procaisse_licence_id,
        nb_orders: 1,
        last_order: data[i].created,
        turnover: data[i].total,
        orders: [data[i]],
      });
    }
  }
  return filtered_list;
};
</script>
<style scoped>
.table-container {
  display: flex;
  justify-content: center;
  height: 100%;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  padding: 1%;
  border: 1px solid #ddd;
}
th {
  background-color: #4a69bd;
  cursor: pointer;
  color: white;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}
* {
  text-align: center;
}
.fa-sitemap{
  cursor: pointer;
}
</style>
