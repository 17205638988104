<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th v-for="h in data.header" v-bind:key="h" @click="sort(h.key)">
            {{ h.title }}
          </th>
          <th>Détail</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="o in data.content" v-bind:key="o">
           <td>{{o.num_order}}</td>
          <td>{{getDateFormat(o.created )}}</td>
          <td>{{getDateFormat(o.order_time.dateTime)}}</td>
          <td>{{o.type}}</td>
          <td>{{o.payment_type}} </td>
          <td>{{o.total.toFixed(2)}} €</td>
          <td>{{o.statut}}</td>
          <td @click="navigate(o)">
            <i class="fa fa-sitemap" aria-hidden="true"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "OrderTable",
  data() {
    return {
      data: {
        header: [
          { title: "Num", key: "num_order" },
          { title: "Passé le", key: "created" },
          { title: "Prévu pour", key: `order_time` },
          { title: "Type commande", key: "type" },
          { title: "Type paiement", key: "payment_type" },
          { title: "Total", key: "total" },
          { title: "Statut", key: "statut" },
        ],
        content: [],
        currentSort: "num_order",
        currentSortDir: "desc",
      },
    };
  },
  methods: {
    sort: function (s) {
      if (s === this.data.currentSort) {
        this.data.currentSortDir =
          this.data.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.data.currentSort = s;
      this.data.content.sort((a, b) => {
        if (s === "created") {
          if (this.data.currentSortDir === "asc") {
            return new Date(a[s]) - new Date(b[s]);
          } else return new Date(b[s]) - new Date(a[s]);
        }
        else if (s === "order_time") {
          if (this.data.currentSortDir === "asc") {
            return new Date(a[s]['dateTime']) - new Date(b[s]['dateTime']);
          } else return new Date(b[s]['dateTime']) - new Date(a[s]['dateTime']);
        }
        else if (s == "statut" || s == "type" || s=="payment_type") {
          if (this.data.currentSortDir === "asc") {
            return a[s][0] > b[s][0] ? -1 : 1;
          } else return a[s][0] > b[s][0] ? 1 : -1;
        } else {
          if (this.data.currentSortDir === "asc") {
            return a[s] - b[s];
          } else return b[s] - a[s];
        }
      });
    },
    getDateFormat: function (value) {
      return moment(value).format("DD/MM/yyyy HH:mm");
    },
      navigate: function (order) {
      localStorage.setItem("current_order", JSON.stringify(order));
      this.$router.push("/orders/detail");
    },
    resolveStatut: function (order) {
      switch (order.status) {
        case "reject":
          order.statut = "Refusée";
          break;
        case "paid":
          order.statut = "Payée";
          break;
        case "pending":
          order.statut = "Valide";
          break;
        case "sent":
          order.statut = "En attente";
          break;
        case "cancel":
          order.statut = "Annulée";
          break;
        case "ready":
          order.statut = "Prête";
          break;
      }
    },
    setStatut: function (orders) {
      orders.forEach((element) => {
        this.resolveStatut(element);
      });
    },
  },
    mounted() {
    this.data.content = JSON.parse(localStorage.orders);
    this.setStatut(this.data.content)
     this.data.content.sort((a, b) => {
        return b['num_order'] - a['num_order'];
      });
  },
};
</script>
<style scoped>
.table-container {
  display: flex;
  justify-content: center;
  height: 100%;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  padding: 1%;
  border: 1px solid #ddd;
}
th {
  background-color: #4a69bd;
  cursor: pointer;
  color: white;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}
* {
  text-align: center;
}
.fa-sitemap {
  cursor: pointer;
}
</style>
