<template>
  <router-view/>
</template>

<style>
html,
body {
  height: 100%;
  margin: 0;
}
*{
  box-sizing: border-box;
}
#app {
  height: 100%;
}
</style>
