import { createRouter, createWebHistory } from 'vue-router'
import OrdersRouter from '../views/OrdersRouter'
import Orders from '../views/Orders'
import Detail from '../views/Detail'
import Stats from '../views/Stats'
import Login from '../views/Login'
const routes = [
  {
    path: '/',
    component: Stats
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/orders',
    component: OrdersRouter,
    children: [
      {
        path:'',
        component:Orders
      },
      {
        path: 'detail',
        component: Detail
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && localStorage.isLogged!="$2a$10$zuBG/0oXsHhrZ/8UsDTa.OxE3e3/94CDy0lyqv.uYBi8FBxEl1L4a") next({ path: '/login' })
  else next()
})

export default router
