<template>
  <div class="orders-container">
    <div class="header">
      {{ restaurant }}
      <i class="fa fa-sign-out" @click="logout()" aria-hidden="true"></i>
    </div>
    <div class="body">
      <!-- <div class="orders-grid">
        <div class="order-item" v-for="o in orders" v-bind:key="o._id">
          <p class="order-info">
            Passée le : <span>{{ getDateFormat(o.created) }}</span>
          </p>
          <p class="order-info">
            Prévue pour :
            <span>{{ getDateFormat(o.order_time.dateTime) }}</span>
          </p>
          <p class="order-info">
            Type : <span>{{ o.type }}</span>
          </p>
          <p class="order-info">
            Méthode de paiement : <span>{{ o.payment_type }}</span>
          </p>
          <p class="order-info">
            Status : <span>{{ o.status }}</span>
          </p>
          <p class="order-info">
            Total : <span class="total">{{ o.total.toFixed(2) }} €</span>
          </p>
          <div class="btn" @click="navigate(o)">Détail Commande</div>
        </div>
      </div> -->
      <OrderTable />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import OrderTable from "../components/table2";
export default {
  name: "OrdersRouter",
  components: {
    OrderTable,
  },
  data() {
    return {
      orders: [],
      restaurant: "",
    };
  },
  methods: {
    logout: function () {
      localStorage.removeItem("isLogged");
      this.$router.go("/");
    },
    getDateFormat: function (value) {
      return moment(value).format("DD/MM/yyyy HH:mm");
    },
    navigate: function (order) {
      localStorage.setItem("current_order", JSON.stringify(order));
      this.$router.push("/orders/detail");
    }
  },
  mounted() {
    //  this.orders = JSON.parse(localStorage.orders);
    this.restaurant = JSON.parse(localStorage.restaurant);
  },
};
</script>
<style scoped>
.orders-container {
  height: 100%;
}
.header {
  text-align: center;
  font-size: large;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1%;
    background: #4a69bd;
  color: white;
}
.body {
  padding: 1%;
}
i {
  float: right;
  cursor: pointer;
}
.orders-grid {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
@media screen and (max-width: 900px) {
  .orders-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (min-width: 901px) {
  .orders-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.order-item {
  width: 100%;
  border-radius: 10px;
  padding: 0 1% 1%;
  background: #f4f4f2;
}
p {
  display: flex;
  justify-content: space-between;
}
span {
  color: grey;
}
.total {
  font-weight: bolder;
  color: red;
}
.btn {
  border: solid 1px;
  border-radius: 5px;
  text-align: center;
  padding: 1%;
  cursor: pointer;
}
</style>
